import { retryEvent } from "@/utils/retryEvent";

export const FB_PIXEL_IDS = (
  process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_IDS || ""
).split(",");

export const pageview = () => {
  retryEvent(
    "fbq",
    () => !!window.fbq,
    () => window.fbq("track", "PageView")
  );
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const fbqTrackEvent = (name: string, options: any = {}) => {
  retryEvent(
    "fbq",
    () => !!window.fbq,
    () => window.fbq("track", name, options)
  );
};

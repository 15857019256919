export const retryEvent = (
  name: string,
  condition: () => boolean,
  eventCallback: () => void,
  maxRetries = 20,
  retryInterval = 1000
) => {
  let retryCount = 0;
  let eventPushed = false;
  let intervalId: any;

  const tryPushEvent = () => {
    if (condition()) {
      eventCallback();
      clearInterval(intervalId);
      eventPushed = true;
      console.log(`${name} Event tracked successfully`);
    } else {
      retryCount++;
      if (retryCount >= maxRetries) {
        clearInterval(intervalId);
        if (!eventPushed) {
          console.error(
            `Maximum retry count reached. ${name} Event not tracked.`
          );
        }
      }
    }
  };

  tryPushEvent();

  if (!eventPushed) {
    intervalId = setInterval(tryPushEvent, retryInterval);
  }
};
